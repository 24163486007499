a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  color: #484848;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 12px;
}

.mt-s {
  margin-top: 12px;
}

.mt-m {
  margin-top: 20px;
}

body {
  background-color: #f0f0f0;
}

.body {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  color: #484848;
}

.container {
  border-radius: 20px;
  background-color: white;
  padding: 12px;
}

.container-full {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.centered-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.location-box {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
}

.text-danger {
  color: #ee5445;
}

.text-success {
  color: #3eeb8a;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.btn-success {
  background-color: #3eeb8a;
  color: white;
}

.btn-danger {
  background-color: #ff6f60;
  color: white;
}

.button {
  font-size: 20px;
  font-weight: bold;
  border: none;
  padding: 20px;
  border-radius: 16px;
  cursor: pointer;
}

.button-placeholder {
  font-size: 20px;
  border: none;
  padding: 20px;
  font-weight: bold;
  flex: 1;
  border-radius: 16px;
  border: #bbb dashed 1px;
  color: #bbb;
}

.challenge-instructions {
  margin-top: 16px;
  color: #ee5445;
  font-size: 20px;
  font-weight: bold;
}

.challenge-section {
  display: flex;
  flex-direction: row;
  padding-top: 30px;
  padding-bottom: 30px;
  justify-content: center;
}

.challenge-input {
  font-size: 32px;
  border-radius: 10px;
  border: #ee5445 solid 2px;
  color: #484848;
  flex: 1;
  text-align: center;
  outline: none;
  font-weight: bold;
}

.challenge-input-1-char {
  flex-grow: unset;
  width: 40px;
}

.challenge-input-4-chars {
  flex-grow: unset;
  width: 120px;
}

.review-textarea {
  flex-grow: unset;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  width: 250px;
  min-height: 50px;
  border: 2px solid #ddd;
  border-radius: 10px;
  padding: 15px;
}

.logo-container {
  margin-top: 40px;
  margin-bottom: 10px;
}

.code-locator-image {
  width: 250px;
}
