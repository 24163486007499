.locker-image-container {
  width: 250px;
  height: 250px;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 20px;
  margin-bottom: 20px;
}

.background-preparing {
  background-image: url("locker-preparing.jpg");
}

.background-ready {
  background-image: url("locker-ready.jpg");
}

.display-info {
  position: absolute;
  top:17px;
  color: white;
  position: relative;
  font-size: 15px;
  font-weight: bold;
}